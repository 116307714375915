
a {
    color: black;
  }
  a:hover {
    color: burlywood;
  }
  
  body {
    font: normal normal 24px Arial, Tahoma, Helvetica, FreeSans, sans-serif;
    color: #444444;
    background: #eeeeee none repeat scroll top left;
    }
  